import "jquery-ui/ui/widgets/sortable";
import List from "list.js";
$(document).on("turbolinks:load", function () {
  $("#curriculum-pages").sortable({
    update: function (event, ui) {
      var pos = ui.item.index();
      var url = ui.item.data("sort-url");
      console.log(url, ui);
      $.ajax({
        type: "GET",
        url: url,
        data: { pos: pos },
        success: function (data, textStatus, jqXHR) {
          window.location = "";
        },
      });
    },
  });

  $("#questions").sortable({
    update: function (event, ui) {
      var pos = ui.item.index();
      var url = ui.item.find(".q_sort").val(pos);
    },
  });
  $(".answers").sortable({
    update: function (event, ui) {
      var pos = ui.item.index();
      var url = ui.item.find(".answer_sort").val(pos);
    },
  });
  var options = {
    valueNames: ["name", "expires", "status"],
  };
  if ($("#required-curriculum-list").length) {
    var curriculumList = new List("curriculum-list", options);
    var requiredCurriculum = new List("required-curriculum-list", options);
    requiredCurriculum.sort("status", { order: "desc" });
    curriculumList.sort("status", { order: "desc" });
  }
});
