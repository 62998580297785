$(document).on("turbolinks:load", function() {
  $(".delete_ajax").on("click", function(e) {
    e.preventDefault();
    button = $(this);
    swal({
      title: "Are you sure?",
      text: $(this).data("delete"),
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        $.ajax({
          url: $(this).attr("href"),
          dataType: "JSON",
          method: "DELETE",
          success: function() {
            $(button)
              .parents("tr")
              .remove();
          }
        });
      }
    });
  });
});
