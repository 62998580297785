$(document).on("turbolinks:load", function() {
  const modal_holder_selector = "#modal-holder";
  const modal_selector = ".modal";

  $(document).on("click", "a[data-modal]", function() {
    const location = $(this).attr("href");
    // Load modal dialog from server
    $.get(location, data => {
      $(modal_holder_selector)
        .html(data)
        .find(modal_selector)
        .modal();
    });
    return false;
  });
  $(document).on("change", ".select_diocese", function(e) {
    console.log("heree");
    diocese_categories_div = $(".curriculum_diocese_categories");
    if ($(this).val() === null) {
      $(diocese_categories_div).html("not valid diocese");
      return;
    }
    dioceses_attr_id = $(this)
      .attr("name")
      .match(/\[.*?\]/g)[1];
    get_categories($(this).val(), dioceses_attr_id, diocese_categories_div);
  });
  function get_categories(
    diocese_id,
    dioceses_attr_id,
    diocese_categories_div
  ) {
    $.ajax({
      method: "POST",
      url: "/admin/curriculums/categories/",
      data: {
        diocese_id: diocese_id
      },
      success: function(categories) {
        $.each(categories, function(i, category) {
          var category_html;
          category_html = "";
          category_html +=
            '<div class="row"><div class="col-sm-6"><span class="checkbox">';
          category_html +=
            '<input  name="curriculum_diocese[curriculum_diocese_categories_attributes]' +
            "[" +
            i +
            '][category_id]" type="hidden" value="' +
            category.id +
            '">' +
            category.name +
            "";
          category_html += "</span></div>";
          category_html += '<div class="col-sm-6">';
          category_html +=
            '<div class="form-group"><select class="select optional form-control" name="curriculum_diocese[curriculum_diocese_categories_attributes]' +
            "[" +
            i +
            '][required]">';
          category_html +=
            '<option value="2">Do not Show</option><option value="1">Optional</option><option value="0">Required</option></select></div>';
          category_html += "</select>";
          category_html += "</div></div>";
          return $(diocese_categories_div)
            .append(category_html)
            .removeClass("hidden");
        });
        return;
      }
    });
  }
  $(document).on("ajax:success", "form[data-modal]", function(event) {
    const [data, _status, xhr] = event.detail;
    const url = xhr.getResponseHeader("Location");
    console.log(url);
    if (url) {
      // Redirect to url
      window.location = url;
    } else {
      // Remove old modal backdrop
      $(".modal-backdrop").remove();
      // Update modal content
      const modal = $(data)
        .find("body")
        .html();
      $(modal_holder_selector)
        .html(modal)
        .find(modal_selector)
        .modal();
    }

    return false;
  });
});
