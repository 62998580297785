// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.jQuery = $;
window.$ = $;
require("@rails/ujs").start();
global.Rails = Rails;
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("bootstrap/dist/js/bootstrap");
require("jquery-validation/dist/jquery.validate");
require("select2/dist/js/select2.js");
require("jquery-mask-plugin/dist/jquery.mask");
require("flatpickr/dist/flatpickr");
require("inputmask/dist/jquery.inputmask.bundle.js");
require("chartkick");
require("chart.js");
window.plyr = require("plyr");
require("blueimp-file-upload/js/jquery.fileupload.js");
// import { Calendar } from '@fullcalendar/core';
// import dayGridPlugin from '@fullcalendar/daygrid';

var moment = require("moment");
window.moment = moment;
require("sweetalert");
require("packs/users");
require("packs/modal-confirm");
require("packs/modals");
require("packs/calendar");
require("packs/sort");
require("packs/jquery-steps");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers";
//import cocoon for nested attributes
import "cocoon";

$(document).on("turbolinks:load", function () {
  $(".open_support").on("click", function () {
    zE("webWidget", "open");
    return false;
  });

  if ($.summernote) {
    $(".summer_note").summernote({});
    $(".summer_note_large").summernote({ height: 200 });
  }
  // remove start
  $(".role").on("click", function () {
    $(this).toggleClass("selected");
  });
  // remove end

  $('[data-toggle="popover"]').popover({
    html: true,
    container: "body",
    title: 'Details <a class="close" href="#");">&times;</a>',
  });
  $(".bpopver").click(function (e) {
    e.stopPropagation();
  });

  $(document).click(function (e) {
    if ($(".popover").has(e.target).length == 0 || $(e.target).is(".close")) {
      $(".bpopver").popover("hide");
    }
  });

  $(".select_location").on("change", function () {
    window.location = "/select_location?lid=" + this.value;
  });

  // keep left nav toggle state
  // var nav_toggleed = Cookies.get("nav-minimize");
  var nav_toggleed = Cookies.get("nav-minimize") === "false";
  var selected_tab = Cookies.get("selected-tab");

  $("body").toggleClass("nav-minimize", nav_toggleed);
  // on load of the page: switch to the currently selected tab
  

  // navbar expand on mobile
  if ($(window).width() <= 576) {
    $("body").addClass("nav-minimize");
  }

  // if clicked on minimize main nav icon on  header
  $(".top-navbar").on("click", ".aside-toggler", function (e) {
    e.preventDefault();
    nav_toggleed = !$("body").is(".nav-minimize");
    $("body").toggleClass("nav-minimize", nav_toggleed);
    Cookies.set("nav-minimize", !nav_toggleed);
  });
  // select2
  $(".select2").select2({
    theme: "bootstrap4",
  });
  // datepicker
  flatpickr(".datepicker", { dateFormat: "m/d/Y", allowInput: true });
  flatpickr(".timepicker", {
    enableTime: true,
    noCalendar: true,
    dateFormat: "h:i K",
    time_24hr: false,
  });

  // tooltips
  $('[data-toggle="tooltip"]').tooltip();

  // validation plugin
  $.validator.setDefaults({
    errorElement: "span",
    errorPlacement: function (error, element) {
      error.addClass("invalid-feedback");
      element.closest(".form-group").append(error);
    },
    highlight: function (element, errorClass, validClass) {
      $(element).addClass("is-invalid");
    },
    unhighlight: function (element, errorClass, validClass) {
      $(element).removeClass("is-invalid").addClass("is-valid");
    },
  });

  // settings button toggle
  $(':radio[name="company[fees_type]"]').change(function () {
    var selected_type = $(this).val();
    switchFees(selected_type, ["flat", "percentage"]);
  });

  $(".validate").validate();
  //$('.amount_mask').inputmask('decimal');
  $(".us_phone").inputmask("(999) 999-9999");
  function searchUers(value) {
    var htmlList = "";
    // getting json data from file for search results
    $.get("/admin/search_users?q=" + value, function (data) {
      for (var i = 0; i < data.length; i++) {
        htmlList += data[i].list_html;
      }
      $("ul.header-search-list").html(htmlList); // Appending list to <ul>
    });
  }
  var timer = null;
  // top search area start
  $(".header-search-input").on("keyup", function (e) {
    if (e.keyCode !== 38 && e.keyCode !== 40 && e.keyCode !== 13) {
      if (e.keyCode == 27) {
        $(".search-input").removeClass("d-block");
        $(".app-content").removeClass("show-overlay");
      }
      var value = $(this).val().toLowerCase();
      var liList = $("ul.header-search-list li"); // get all the list items of the search
      liList.remove();
      if (value != "") {
        $(".header-search-list").addClass("d-block");
        $(".app-content").addClass("show-overlay");
        clearTimeout(timer);
        timer = setTimeout(searchUers(value), 1000);
      } else {
        // If filter box is empty
        if ($(".header-search-list").hasClass("d-block")) {
          $(".header-search-list").removeClass("d-block");
        }
        $(".app-content").removeClass("show-overlay");
      }
    }
    $("input[type=search]").on("search", function () {
      $(".header-search-list").removeClass("d-block");
      $(".app-content").removeClass("show-overlay");
    });
  });
  // top search area end
  // open search card and close it
  $(".open-search").on("click", function () {
    $(".search-card").toggleClass("d-none");
    return false;
  });

  $(".confirmation").click(function (e) {
    e.preventDefault(); // Prevent the href from redirecting directly
    var linkURL = $(this).attr("href");
    var text = $(this).data("delete");
    warnBeforeRedirect(linkURL, text);
  });

  function warnBeforeRedirect(linkURL, text) {
    swal({
      title: "Are you sure?",
      text: text,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        window.location.href = linkURL;
      }
    });
  }

  // store the currently selected tab in the hash value
  
  // make entrie row clickable
  $(".clickable-row").on("click", "td:not(.special-td)", function () {
    window.location = $(this).parent().data("url");
  });
  // list of sites based on role
  $(".dynamic_sites_list").select2({
    theme: "bootstrap4",
    ajax: {
      url: "/admin/dynamic_sites_list",
      dataType: "json",
      processResults: function (data) {
        return {
          results: data,
        };
      },
    },
  });
  // list of events based on role
  $(".dynamic_events_list").select2({
    theme: "bootstrap4",
    ajax: {
      url: "/admin/dynamic_events_list",
      dataType: "json",
      processResults: function (data) {
        return {
          results: data,
        };
      },
    },
  });
});

// in case of show hide based on value use this function
function switchFees(type, match) {
  if (type == match[0]) {
    $(".s1").removeClass("d-none");
    $(".s2").addClass("d-none");
  } else if (type == match[1]) {
    $(".s2").removeClass("d-none");
    $(".s1").addClass("d-none");
  }
}

// var dataTable;
// var dataTable2;
// $(document).on("turbolinks:load", function() {
//   dataTable = $(".dataTable").DataTable();
//   dataTable2 = $(".dataTable_custom").DataTable({
//     order: [
//       [4, "asc"],
//       [0, "asc"]
//     ]
//   });
// });
// $(document).on("turbolinks:before-cache", function() {
//   console.log("eee");
//   if (dataTable !== null) {
//     dataTable.destroy();
//     dataTable = null;
//   }
//   if (dataTable2 !== null) {
//     dataTable2.destroy();
//     dataTable2 = null;
//   }
// });

const dataTables = [];
const dataTablesCustom = [];
const dataTablesNoDefaultSort = [];
// dt_global_custom
document.addEventListener("turbolinks:load", () => {
  if (dataTables.length === 0 && $(".dt_global").length !== 0) {
    $(".dt_global").each((_, element) => {
      dataTables.push($(element).DataTable());
    });
  }
  if (
    dataTablesNoDefaultSort.length === 0 &&
    $(".dt_global_no_df_sort").length !== 0
  ) {
    $(".dt_global_no_df_sort").each((_, element) => {
      dataTablesNoDefaultSort.push($(element).DataTable({ order: [] }));
    });
  }
  if (dataTablesCustom.length === 0 && $(".dt_global_custom").length !== 0) {
    $(".dt_global_custom").each((_, element) => {
      dataTablesCustom.push(
        $(element).DataTable({
          order: [
            [4, "asc"],
            [0, "asc"],
          ],
        })
      );
    });
  }
});

document.addEventListener("turbolinks:before-cache", () => {
  while (dataTables.length !== 0) {
    dataTables.pop().destroy();
  }
  while (dataTablesNoDefaultSort.length !== 0) {
    dataTables.pop().destroy();
  }
  while (dataTablesCustom.length !== 0) {
    dataTablesCustom.pop().destroy();
  }
});
