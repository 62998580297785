$(document).on("turbolinks:load", function() {
  $("body").on("click", ".usc-notes-link", function(e) {
    e.preventDefault();
    var note = $(this).data("note");
    $(".cert-note").html(note);
    $("#usc-notes").modal("show");
  });

  var clickOnPopupLink = function() {
    $("body").on("click", ".notes-popup-link", function() {
      $("#NotesModal").modal("show");
    });
  };
  clickOnPopupLink();
  $("body").on("click", ".has_hits_open", function() {
    var id = $(this).data("id");
    $("#bid_hits").val(id);
    $("#has_hits_model").modal("show");
    return false;
  });

  // bc expire months
  var bc_months = $("#bc_months").data("months");
  // cert expire months
  var cert_months = $("#cert_months").data("months");
  // selected bc date

  $(".bc_date_picker").on("change", function() {
    if (bc_months != 0) {
      setexpire(
        bc_months,
        $(".bc_date_picker"),
        $("#background_check_expire_date")
      );
    }
  });
  // slected cert date
  $(".cert_date_picker").on("change", function() {
    if (cert_months != 0) {
      setexpire(
        cert_months,
        $(".cert_date_picker"),
        $("#certification_status_expire_date")
      );
    }
  });

  // if never expire is changed
  $("#background_check_never_expire").on("change", function() {
    if ($("#background_check_never_expire").is(":checked")) {
      // if bc never expire is checked

      $("#background_check_expire_date").val("");
      $(".expire_date").hide();
    } else {
      // if bc never is unchecked
      $(".expire_date").show();
      setexpire(
        bc_months,
        $(".bc_date_picker"),
        $("#background_check_expire_date")
      );
    }
  });

  // if never expire is changed
  $("#certification_status_never_expire").on("change", function() {
    if ($("#certification_status_never_expire").is(":checked")) {
      // if bc never expire is checked
      $("#certification_status_expire_date").val("");
      $(".expire_date_cert").hide();
    } else {
      // if bc never is unchecked
      $(".expire_date_cert").show();

      setexpire(
        cert_months,
        $(".cert_date_picker"),
        $("#certification_status_expire_date")
      );
    }
  });
});

function setexpire(months, picked, elm) {
  picked_date = moment(picked.val(), "MM/DD/YYYY");
  if (picked_date.isValid()) {
    expire_date = picked_date
      .add(months, "months")
      .format("MM/DD/YYYY")
      .toString();
    elm.val(expire_date);
  } else {
    elm.val("");
  }
}
